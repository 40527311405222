import Model from '@/util/Model'

export default class Location extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'description',
        type: 'string'
      },
      {
        name: 'officeName',
        type: 'string'
      },
      {
        name: 'lang',
        type: 'string',
        default: 'en'
      },
      {
        name: 'type',
        type: 'string'
      },
      {
        name: 'contact',
        idOnly: true
      },
      {
        name: 'owner',
        idOnly: true
      },
      {
        name: 'address',
        type: 'object',
        default: {
          country: '',
          prov: '',
          city: '',
          street: '',
          apt: '',
          code: ''
        }
      },
      {
        name: 'phone',
        type: 'string'
      },
      {
        name: 'email',
        type: 'string'
      },
      {
        name: 'users',
        type: 'array',
        default: [] // [{user: _id, role: [owner, editor, viewer]}]
      },
    ]

    if (data && !data.address) {
      data.address = {
        country: data.country || '',
        prov: data.prov || '',
        city: data.city || '',
        street: data.street || '',
        apt: data.apt || '',
        code: data.code || ''
      }
      delete data.country
      delete data.prov
      delete data.city
      delete data.street
      delete data.apt
      delete data.code
    }

    super({ fields: fields }, data)
  }
}
