<template>
  <div
    v-if="visible"
    id="modal-template"
    class="modal"
    transition="modal"
    @hide="close"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <!-- <div class="modal-container" :style="{width: modalwidth, height: modalheight}"> -->
        <!-- <div class="modal-container" :style="{width: modalwidth}"> -->
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header" />
            <a v-if="closable" class="modal-default-button" @click="close()">
              <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </a>
          </div>

          <div class="modal-body">
            <slot name="body" />
          </div>

          <div class="modal-actions">
            <slot name="actions" />
          </div>

          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash/fp'

export default {
  name: 'basic-modal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modalheight: { type: String, default: '90%' },
    modalwidth: { type: String, default: '90%' },
    closable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close(val) {
      // this.showModal = false
      this.$emit('hide', val)
    }
    // show (val) {
    //   console.info(' :: modal heard show')
    //   this.input = val
    //   _.each(i => {
    //     this.actionValues[i.name] = i.value
    //   }, this.actions)
    //   this.res = ''
    //   // this.showModal = true
    // }
  }
}
</script>

<style type="text/css" media="screen">
p.warn {
  text-align: center;
  color: red;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  /* display: table-cell; */
  vertical-align: middle;
}

.modal-container {
  position: fixed;
  /*    width: 300px;*/
  /*    margin: 0px auto;*/
  /*    padding: 20px 30px;*/
  /*    padding: 3rem;*/
  /* padding: 0.5rem 0.5rem 2rem 2rem; */
  padding: 1rem 1rem 0 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  /*    transition: all .3s ease;*/
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  position: relative;
  padding: 1rem 1rem 0 1rem;
  /* background-color: #eee; */
  /* border-bottom: #cccc solid 1px; */
}

.modal-header h2 {
  padding-top: 0;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  /* text-align: center; */
}

.modal .modal-body {
  height: 100%;
  margin: 1rem 0 0 0;
  text-align: left;
  padding: 1rem 1rem 0 1rem;
  /* padding: .5rem; */
}
.modal-body ul {
  margin-left: 1rem;
}

.modal-actions,
.modal-actions.control {
  text-align: center;
}

.modal-default-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-footer {
  text-align: center;
}

button.primary {
  margin-right: 1rem;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
