import _ from 'lodash/fp'
import { v4 as uuid } from 'uuid'
import _c from '@/../../../templates/site/UI/common/common-constants'
import * as util from './util'


const fieldTypes = {
  string: val => typeof val === 'string',
  email: val => typeof val === 'string',
  number: val => _.isFinite(val),
  array: val => _.isArray(val),
  object: val => _.isObject(val)
}

export default class Model {
  constructor (_config, data /*, store*/) {
    this.config = {
      idProperty: '_id',
      hasId: false,
      fields: [],
      isValid: {}
    }
    // add custom methods to instance
    // if(_config.methods){
    // 	Object.assign(this, _config.methods);
    // }
    if (_config.clientMethods) {
      Object.assign(this, _config.clientMethods)
      delete _config.clientMethods
    }
    _config.serverMethods && delete _config.serverMethods

    Object.assign(this.config, _config)
    // for convenience, hoist useful props as refs up to root
    this.fields = this.config.fields
    this.isValid = this.config.isValid
    this.hasId = this.config.hasId

    let _data = this.add(data)
    // return this.add(data).then((_data) => {
    // if not populating from payload, ensure default locale object is constructed, if needed
    let _content = _.find(item => item.name == 'content', this.fields)
    if (_content && _content.type == 'localizable') {
      for (let _lang of _c.LOCALES) {
        if (!_data.content[_lang]) _data.content[_lang] = {}
        for (let _fld of _content.members) {
          if (!_data.content[_lang] || !_data.content[_lang][_fld]) {
            _data.content[_lang][_fld] = ''
          }
        }
      }
    }
    this.data = _data
    // });
  }

  add = data => {
    let rec = {}

    if (!data[this.config.idProperty]) {
      rec.internalId = uuid()
      this.hasId = false
    } else {
      rec[this.config.idProperty] = data[this.config.idProperty]
      this.hasId = true
    }
    if (this.config.fields.length) {
      this.config.fields.forEach(field => {
        if (typeof field === 'string') {
          rec[field] = data[field]
          this.config.isValid[field] = true
        } else {
          if (field.convert) {
            rec[field.name] = field.convert(data[field.name] || field.default)
          }
          if (field.calculate) {
            rec[field.name] = field.calculate(data[field.name] || field.default)
          }
          if (field.alias) {
            rec[field.alias] = data[field.name] || field.default
          } else {
            if (this.hasId) {
              rec[field.name] = data[field.name]
            } else {
              rec[field.name] = data[field.name] || field.default
            }
          }
          this.config.isValid[field.name] = this.validateField(
            field,
            data[field]
          )
        }
      })
    } else {
      // return Promise.resolve(Object.assign(rec, data));
      return Object.assign(rec, data)
    }
    // return Promise.resolve(rec);
    return rec
  }

  validate = () => {
    const _test = _.assign({}, this.getData())
    for (let field in _test) {
      if (!field || ~['internalId', 'hasId', 'content', '_id'].indexOf(field)) {
        continue
      } else if (~this.fields.indexOf(field)) {
        this.isValid[field] = true
      } else {
        let fieldRecord = _.find(f => f.name === field, this.fields)
        if (fieldRecord) {
          if (fieldRecord.idOnly)
            this.validateField(fieldRecord, this.data[field])
          else this.validateField(fieldRecord, _test[field])
        } else {
          console.info(' ::: No field value to validate', _test[field])
        }
      }
    }
    return _.values(this.isValid).every(v => v)
  }

  validateField = (field, val) => {
    // test field props for validators
    if ('string' === typeof field) return (this.isValid[field] = true)
    // is required?
    if (field.required && !util.isSet(val))
      return (this.isValid[field.name] = false)

    // type correct?
    if (field.type) {
      if (
        fieldTypes[field.type] &&
        util.isSet(val) &&
        !fieldTypes[field.type](val)
      )
        return (this.isValid[field.name] = false)
      // else if(field.type !== typeof val) return this.isValid[field.name] = false;
    }
    // matches?
    // if (
    //   field.match &&
    //   _c.MODEL.match[field.match] &&
    //   util.isSet(val) &&
    //   !val.match(_c.MODEL.match[field.match])
    // )
    //   return (this.isValid[field.name] = false)
    // validator?
    if (field.validate && util.isSet(val) && !field.validate(val))
      return (this.isValid[field.name] = false)
    // string length
    if (
      field.maxLength &&
      util.isSet(val) &&
      'string' === typeof val &&
      val.length > field.maxLength
    )
      return (this.isValid[field.name] = false)
    if (
      field.minLength &&
      util.isSet(val) &&
      'string' === typeof val &&
      val.length < field.minLength
    )
      return (this.isValid[field.name] = false)
    // number constraints
    if (field.max && util.isSet(val) && _.isFinite(val) && val > field.max)
      return (this.isValid[field.name] = false)
    if (field.min && util.isSet(val) && _.isFinite(val) && val < field.min)
      return (this.isValid[field.name] = false)

    return (this.isValid[field.name] = true)
  }

  get = field => {
    return this.data[field]
  }

  set = (field, val) => {
    this.data = _.set(field, val, this.data)
    return val
    // return this.data[field] = val;
  }

  getData = () => {
    //console.info('fields',this.fields); return;
    // reduce fields that contain array of refs to just _ids...
    let _data = _.assign({}, this._removeMetaFields(this.data))
    for (let field of this.fields) {
      if (
        _.isNil(this.data[field.name || field]) ||
        (this.data[field.name || field] === '' &&
          (typeof field === 'string' || !field.required))
      ) {
        delete _data[field.name || field]
        continue
      }
      if (typeof field !== 'object') continue
      if (field.idOnly) {
        if (
          (field.type && field.type === 'array') ||
          (field.default && _.isArray(field.default))
        ) {
          _data[field.name] = _.compact(
            _.map(item => {
              if (typeof item === 'string') return item
              else if (item._id) return item._id
              else {
                return item
              }
            }, _data[field.name])
          )
        } else {
          _data[field.name] = _data[field.name]._id || _data[field.name]
        }
      }
    }
    return _data
  }

  valid = () => {}

  _removeMetaFields = rec => {
    let keys = _.keys(rec)
    let removeFields = ['hasId', 'internalId']

    for (let key of keys) {
      if (~removeFields.indexOf(key)) delete rec[key]
      // and localizable fields
      if (key == 'content') {
        delete rec.content.name
        delete rec.content.type
        delete rec.content.members
      }
    }

    return rec
  }
}
