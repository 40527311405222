import _ from 'lodash/fp'

/**
 * Caution: recursion.
 * takes an array of hierarchical record, using 'parent' property
 * returns array with 'children' property containing records subordinate to parents
 * also returns flat array of records with value = id, content, and depth for use in menus
 */
export function getNested (
  src,
  parent = null,
  depth = 0,
  collection = [],
  menu = []
) {
  const _collection = collection.slice() // make a copy...
  const _src = src.slice() // make a copy...
  let _menu = menu.slice() // make a copy...

  // if no hierarchy, just return flat map.
  if (
    _src.every(i => {
      if (_.isEmpty(i)) return true
      if (_.isEmpty(i.parent)) return true
    })
  ) {
    // no children
    _menu = _.map(item => {
      return {
        value: item._id,
        content: item.content,
        depth: 0
      }
    }, _.compact(_src))
    return {
      _collection: _src,
      _menu: _menu
    }
  }

  if (parent === null) {
    const ancestors = _.filter(item => !item.parent, _src)
    for (let i = 0, c = ancestors.length; i < c; i++) {
      const _nested = getNested(
        _src,
        ancestors[i]._id,
        depth + 1,
        _collection,
        _menu
      )
      if (_nested.children.length) {
        ancestors[i].children = _nested.children
      }
      _menu = [
        {
          value: ancestors[i]._id,
          content: ancestors[i].content,
          depth: depth
        }
      ].concat(_nested._menu)
      _collection.push(ancestors[i])
    }
  } else {
    const children = _.filter(item => item.parent === parent, _src)
    if (children.length) {
      for (let i = 0, c = children.length; i < c; i++) {
        const _nested = getNested(
          _src,
          children[i]._id,
          depth + 1,
          _collection,
          _menu
        )
        if (_nested.children.length) {
          children[i].children = _nested.children
        }
        _menu = [
          {
            value: children[i]._id,
            content: children[i].content,
            depth: depth
          }
        ].concat(_nested._menu)
      }
      return {
        children,
        _menu
      }
    }
    return {
      children: [],
      _menu: _menu
    }
  }
  return {
    _collection,
    _menu
  }
}

export function isSet (val) {
  if (typeof val === 'undefined') return false
  if (_.isNull(val)) return false
  if (typeof val === 'string' && !val.length) return false
  if (_.isObjectLike(val) && _.isEmpty(val)) return false
  return true
}

export function localize (data) {
  if (_.isEmpty(data.body)) return null

  let _data = data.body
  const out = []
  // if data is an array
  if (!_.isArray(_data)) {
    _data = [_data]
  }
  for (const itm of _data) {
    if (!itm.content) {
      out.push(itm)
      continue
    }
    const _itm = Object.assign({}, itm)
    for (const key in _itm.content[document.documentElement.lang]) {
      _itm[key] = _itm.content[document.documentElement.lang][key]
    }
    out.push(_itm)
  }
  return out
}

export function isOrderValid (order) {
  // debugger;
  const _missing = []
  const _invalid = []
  // return new Promise((resolve, reject) => {
  if (!_.keys(order.items).length) _missing.push('items')
  if (!order.purchaser) _missing.push('purchaser')

  if (!order.groupId) {
    if (!order.shipping.address.country) _missing.push('shipping.country')
    if (typeof order.shipping.address.country !== 'string')
      _invalid.push('shipping.country')
    if (!order.shipping.address.prov) _missing.push('shipping.prov')
    if (typeof order.shipping.address.prov !== 'string') _invalid.push('shipping.prov')
    if (!order.shipping.address.city) _missing.push('shipping.city')
    if (typeof order.shipping.address.city !== 'string') _invalid.push('shipping.city')
    if (!order.shipping.address.street) _missing.push('shipping.street')
    if (typeof order.shipping.address.street !== 'string')
      _invalid.push('shipping.street')
    if (!order.shipping.address.code) _missing.push('shipping.code')
    if (typeof order.shipping.address.code !== 'string') _invalid.push('shipping.code')
    if (!order.shipping.phone) _missing.push('shipping.phone')
    if (typeof order.shipping.phone !== 'string')
      _invalid.push('shipping.phone')
    if (!order.shipping.email) _missing.push('shipping.email')
    if (typeof order.shipping.email !== 'string')
      _invalid.push('shipping.email')
  }

  if (!order.billing.address.country) _missing.push('billing.country')
  if (typeof order.billing.address.country !== 'string')
    _invalid.push('billing.country')
  if (!order.billing.address.prov) _missing.push('billing.prov')
  if (typeof order.billing.address.prov !== 'string') _invalid.push('billing.prov')
  if (!order.billing.address.city) _missing.push('billing.city')
  if (typeof order.billing.address.city !== 'string') _invalid.push('billing.city')
  if (!order.billing.address.street) _missing.push('billing.street')
  if (typeof order.billing.address.street !== 'string') _invalid.push('billing.street')
  if (!order.billing.address.code) _missing.push('billing.code')
  if (typeof order.billing.address.code !== 'string') _invalid.push('billing.code')
  if (!order.billing.phone) _missing.push('billing.phone')
  if (typeof order.billing.phone !== 'string') _invalid.push('billing.phone')

  if (!order.price.shipping) _missing.push('price.shipping')
  if (isNaN(order.price.shipping)) _invalid.push('price.shipping')

  return {
    missing: _missing,
    invalid: _invalid
  }
}

export function shippingMap (service, ref) {
  return _.reduce(
    (acc, k) => {
      const v = ref[k]
      v.get
        ? v.key
          ? (acc[k] = _.get(v.key, service))
          : (acc[k] = _.reduce(
              (ac, val, ky) => {
                ac[ky] = val.get ? _.get(val.key, service) : val.value
                return ac
              },
              {},
              v.map
            ))
        : (acc[k] = v.value)
      return acc
    },
    {},
    _.keys(ref)
  )
}
